import calendar from '../components/calendar'
import web_credit_creditFlow from '@/lib/data-service/default/web_credit_creditFlow'
import web_credit_creditFlowDetail from '@/lib/data-service/default/web_credit_creditFlowDetail'
import web_credit_creditFlowRemark from '@/lib/data-service/default/web_credit_creditFlowRemark'
import moment from 'moment';
import scrollTop from '@/lib/common-service/scrollTop'
import web_credit_creditFlowExport from '@/lib/data-service/default/web_credit_creditFlowExport'
export default {
    data() {
        return {
            searchForm: {
                currentPage: 1,
                pageSize: 10
            },
            totalPage: 0,
            dateShow: null,
            loading: false,
            dialogTableVisible: false,
            day: null,
            detail: {},
            flowDetail: [],
            navList: {
                type: [
                    { text: '全部', statu: true, value: '' },
                    { text: '购物', statu: false, value: 1 },
                    { text: '还款', statu: false, value: 2 },
                    { text: '退款', statu: false, value: 3 }
                ],
                business: [
                    { text: '全部', statu: true, value: '' },
                    { text: '旅游', statu: false, value: 2 },
                    { text: '机票', statu: false, value: 1 },
                    { text: '酒店', statu: false, value: 3 },
                ]
            },
        }
    },
    components: { calendar },
    created() {
        this.search()
    },
    methods: {
        search(val) {
            let data = this.searchForm
            this.loading = true
            web_credit_creditFlow(data).then(res => {
                console.log(res)
                this.loading = false
                this.detail = res
                this.totalPage = res.pageResult.totalCount
                scrollTop(400)
            }, error => this.loading = false)
        },
        handleSizeChange(val) {
            this.searchForm.pageSize = val
            this.search()
        },
        handleCurrentChange(val) {
            this.searchForm.currentPage = val
            this.search()
        },
        getTime(value) {
            let data = this.searchForm
            value ?
                (data.tradingHourStart = moment(value[0]).format(`YYYY-MM-DD`),
                    data.tradingHourEnd = moment(value[1]).format(`YYYY-MM-DD`)) :
                (data.tradingHourStart = '', data.tradingHourEnd = '', this.dateShow = null)
            this.search()
        },
        remark(val) {
            this.$prompt('请输入备注信息', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputValue: val.remark
            }).then(({ value }) => {
                let flowId = val.id
                let remark = value
                web_credit_creditFlowRemark({ flowId, remark }).then(res => {
                    res.code == this.SUCCESS_CODE ?
                        (this.$message({ type: "success", message: "修改成功！" }), this.search()) : ''
                })
            }).catch(() => {
            });
        },
        getDetail(val) {
            web_credit_creditFlowDetail({ flowId: val.id }).then(res => {
                this.dialogTableVisible = true
                this.flowDetail = [res.result]
            })
        },
        getBtnType(item, list, type) {
            list.map(item => {
                item.statu = false
            })
            item.statu = true
            type === 'type' ? this.searchForm.flowType = item.value : this.searchForm.businessType = item.value
            this.searchForm.currentPage = 1
            this.search()
        },
        exportExcel() {
            let time = this.searchForm
            if (time.tradingHourEnd) {
                let endTime = new Date(time.tradingHourEnd).valueOf()
                let threeMonths = new Date(moment(time.tradingHourStart).add(6, 'months')).valueOf()
                console.log(endTime > threeMonths)
                if (endTime > threeMonths) return this.$message({ type: 'warning', message: '只能导出6个月内的交易记录!' })
            }
            let data = Object.assign({
                flowType: '',
                businessType: ''
            }, this.searchForm)
            delete data.pageSize
            delete data.currentPage
            web_credit_creditFlowExport(data).then(res => {
                let tempLink = document.createElement("a");
                tempLink.style.display = "none";
                tempLink.href = `http://${res.result}`;
                tempLink.setAttribute("download", '');
                document.body.appendChild(tempLink);
                tempLink.click();
                document.body.removeChild(tempLink);
            })
        }
    }
}
