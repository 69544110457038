const __request = require(`./__request`);
/**
 * 授信流水详情
 */
export default function(val){
    var pParameter = {}
    pParameter.method = "POST"
    pParameter.urlSuffix = "/web/credit/creditFlowDetail"
    pParameter.data = val
    return new Promise(function(resolve, reject){
        resolve(__request(pParameter))
    })
}
